<script lang="ts">
	export let compiled: string;

	let iframe: HTMLIFrameElement;

	function update(code: string) {
		iframe.contentWindow.postMessage(code, "*");
	}

	$: iframe && compiled && update(compiled);

	const srcdoc = `
<!doctype html>
<html>
	<head>
		<style>
			body {
				margin:0;
				height:100%;
				overflow:hidden;
			}
			#sandbox {
				height:100vh;
				width:100%;
				display:flex;
				flex-direction:column;
				justify-content:center;
				align-items:center;
			}
		</style>
		<script type="module">

			let c;

			function update(source) {
				const blob = new Blob([source], { type: 'text/javascript' });
				const url = URL.createObjectURL(blob);

				import(url).then(({ default: App }) => {
					if (c) c.$destroy();

					const target = document.querySelector('#sandbox');

					target.innerHTML = '';
					c = new App({ target })
				})
			}

			window.addEventListener('message', event => {
				update(event.data)
			}, false)
		<\/script>
	</head>
	<body><div id="sandbox" /></body>
</html>
	`;
</script>

<section>
	<iframe title="Rendered REPL" bind:this={iframe} {srcdoc} />
</section>


<style>
	iframe {
		width:100vw;
		border:none;
		outline:none;
		height:100vh;
	}
</style>